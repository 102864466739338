import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import grFlag from "./gr_flag.png"
import enFlag from "./en_flag.png"
import "./lang_style.css"
import skeleton from "../../data/skeleton.json"
import {useEffect, useState} from "react";
import {useGDRP} from "../provider_GDPR";
import {FaGlobe} from "react-icons/fa";

export const LanguagesSelect = () =>{
    const [title, setTitle] = useState()
    const {language, setLangEN, setLangGR, switchShowLang, showLangSelect, showLang} = useGDRP()

    useEffect(() => {
        setTitle(skeleton[language]["language"]["title"])
    }, [language]);

    return(
        <div>
            {showLangSelect?
            <Container >
                <Row className="justify-content-center">
                    {title}
                </Row>
                <Row className="justify-content-center" xs={2} sm={2}>
                    <Col className="d-flex justify-content-center align-items-center">
                        <div className={"imgContainer"}>
                            <img className={"bttnFlag"}
                                 src={grFlag} alt="Ελληνικά"
                                 onClick={()=> {setLangGR(); switchShowLang()}}
                            />
                        </div>
                    </Col>
                    <Col  className="d-flex justify-content-center align-items-center">
                        <img className={"bttnFlag"}
                             src={enFlag} alt="Εnglish"
                             onClick={()=> {setLangEN();switchShowLang()}}/>
                    </Col>
                </Row>
            </Container>:
                <div className="langIcon"  onClick={() => {showLang()}}>
                    <FaGlobe/>
                </div>
            }
        </div>
    )
}
