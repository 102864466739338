import React, {createContext, useContext, useEffect, useLayoutEffect, useState} from "react";

const ContextGDPR = createContext()

export const useGDRP = () => useContext(ContextGDPR);


export const PostStatus = Object.freeze({
    Error: Symbol("error"),
    Loading: Symbol("loading"),
    Success: Symbol("success"),
    Init: Symbol("initialize"),
    Pending: Symbol("pending"),
})

export function ProviderGDPR({...props}){
    // Database
    const dbEndpoint = 'http://'+window.location.host+'/client'
    // Language
    const [language, setLanguage] = useState('gr')
    const setLangGR = () => setLanguage("gr")
    const setLangEN = () => setLanguage("en")
    const [showLangSelect, setShowLangSelect] = useState(true)
    const switchShowLang = () => setShowLangSelect(!setShowLangSelect)
    const showLang = () => setShowLangSelect(true)
    // Manage the state of rules reading
    const [hasRead, setHasRead] = useState(false)
    const is_read = () => setHasRead(true)

    // Status update
    const [status, setStatus] = useState(PostStatus['Init'])
    const statusError = () => setStatus(PostStatus['Error'])
    const statusLoading = () => setStatus(PostStatus['Loading'])
    const statusSuccess = () => setStatus(PostStatus['Success'])
    const statusPending = () => setStatus(PostStatus['Pending'])
    const reInitStatus = () => setStatus(PostStatus['Init'])
    // useEffect(() => {
    //     console.log("Status: ", status)
    // }, [status]);


    // Awareness
    const [hasAware, setHasAware] = useState(false)
    const justAware = () => setHasAware(true)

    return(
        <ContextGDPR.Provider
            value={{
                dbEndpoint, // DataBase URI
                is_read, hasRead, // About GDPR rules reading
                status, statusError, statusLoading, statusSuccess, statusPending, reInitStatus, // About status update
                language, setLangEN, setLangGR, switchShowLang, showLangSelect, showLang, // About language
                hasAware, justAware // About awareness
            }}
        >
            {props.children}
        </ContextGDPR.Provider>
    )
}
