import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import React, {useEffect, useLayoutEffect, useState} from "react";
import {useGDRP} from "../provider_GDPR";
import Spinner from 'react-bootstrap/Spinner';
import {FcApproval} from "react-icons/fc";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import skeleton from "../../data/skeleton.json"

export const PostFailureAlert = (error) =>{
    // Status
    const [show, setShow] = useState(true);
    const {statusError, reInitStatus} = useGDRP()
    useEffect(() => {
        statusError()
    }, []);
    if (show) {
        return (
            <Alert variant="danger" onClose={() => {setShow(false); reInitStatus()}} dismissible>
                <Alert.Heading>DB Post has failed</Alert.Heading>
                <p>
                    Change this and that and try again. Duis mollis, est non commodo
                    luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                    Cras mattis consectetur purus sit amet fermentum.
                </p>
            </Alert>
        );
    }
}

export const PostSuccessAlert = (message) =>{
    //  Language
    const {language} = useGDRP()
    const [labels, setLabels] = useState({})
    useLayoutEffect(()=>{
        setLabels(skeleton[language]["menu"])
    },[language])

    // Status
    const [show, setShow] = useState(true);
    const {statusSuccess, justAware} = useGDRP()
    useEffect(() => {
        statusSuccess()
        justAware();
    }, []);
    if (show) {
        return (

            <div>
                <Container>
                    <Row className="justify-content-center">
                        <FcApproval size={70}/>
                    </Row>
                    <Row className="justify-content-center">
                        {labels["succeed_message"]}
                    </Row>
                </Container>
            </div>
            // <Alert variant="success" onClose={() => setShow(false)} dismissible>
            //     <Alert.Heading>DB Post has SUCCEED</Alert.Heading>
            //     {/*<p>*/}
            //         <pre>{JSON.stringify(message, null, 2)}</pre>
            //     {/*</p>*/}
            // </Alert>
        );
    }
}



export const PostLoadingAlert = () =>{
    const [show, setShow] = useState(true);
    const {statusLoading, reInitStatus} = useGDRP()
    useEffect(() => {
        statusLoading()
    }, []);
    if (show) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
}