import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useEffect, useLayoutEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import {formDataTemplate, PostResponse, setFormData} from "./post_requests";
import {useGDRP} from "../provider_GDPR";
import skeleton from '../../data/skeleton.json'
import InfoIcon from "./info_icon";


const ConsentForm = ({...props}) => {
    const {inputs, setInputs} = props
    // Language
    const {language} = useGDRP()
    const [labels, setLabels] = useState({})
    useLayoutEffect(()=>{
        setLabels(skeleton[language]["submit_form"])
    },[language])

    // CheckBoxes
    const [checkGDPR, setCheckGDPR] = useState(inputs['gdpr'])
    const [checkNewsletter, setCheckNewsletter] = useState(inputs['newsletter'])
    const switchNewsletter = () => setCheckNewsletter(!checkNewsletter)
    const switchGDPR = () => setCheckGDPR(!checkGDPR)


    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        switch (name){
            case 'gdpr':
                value = !checkGDPR;
                switchGDPR();
                break;
            case 'newsletter':
                value = !checkNewsletter;
                switchNewsletter();
                break
            default:
                // escapes all user inputs, ensuring that any potentially harmful code is treated as plain text.
                // const new_value = <div>{event.target.value}</div>
                // value = new_value.props.children
                value = event.target.value
        }
        const v = value;
        setInputs(values => ({...values, [name]: v}))
    }
    // Form Control
    const [validated, setValidated] = useState(false);
    const {statusPending} = useGDRP()
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else{
            statusPending()
        }
        setValidated(true);
        console.log('All inputs: ', inputs, validated)
    };

    return (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                        {labels["email"]}
                    </Form.Label>
                    <Col sm={10}>
                        <FloatingLabel controlId="floatingInputGrid" label={labels["email_placeholder"]}>
                            <Form.Control
                                required
                                type="email"
                                placeholder={labels["email_example"]}
                                name='email'
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {labels["email_feedback"]}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                    <Form.Label column sm={2}>
                        {labels["name"]}
                    </Form.Label>
                    <Col sm={10}>
                        <FloatingLabel controlId="floatingInputGrid" label={labels["name_placeholder"]}>
                            <Form.Control
                                type="name"
                                name="name"
                                placeholder={labels["name_example"]}
                                onChange={handleChange}
                            />
                        </FloatingLabel>

                    </Col>
                </Form.Group>


                <Form.Group as={Row} className="mb-3" controlId="formCheckGDPR">
                    <Col sm={{ offset:2, span:9}} xs={{span: 10}}  md={{offset:2, span: 6}} xl={{span:4, offset:2}}>
                        <Form.Check required
                                    name="gdpr"
                                    //switchGDPR is a step backward
                                    onChange={handleChange}
                                    label={labels["gdpr"]}
                                    feedbackType="invalid"
                                    feedback={labels["gdpr_feedback"]}
                        />
                    </Col>
                    <Col sm={{span:1}} xs={{span: 1}}>
                        <InfoIcon
                            tip={labels["information_tips_gdpr"]}
                            placement={'top'}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formCheckNewsletter">
                    <Col xs={{span: 10}} sm={{ offset:2, span:9}}  md={{offset:2, span: 6}}  xl={{span:4, offset:2}}>
                        <Form.Check
                            label={labels["newsletter"]}
                            name="newsletter"
                            onChange={handleChange}
                        />
                    </Col>
                    <Col sm={{span:1}} xs={{span: 1}}>
                        <InfoIcon
                            tip={labels["information_tips_newsletter"]}
                            placement={'bottom'}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formSubmitBttn">
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Button type="submit">
                            {labels["submit_bttn"]}
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
    );
}

export default ConsentForm;