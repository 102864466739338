import {PostResponse} from "./post_requests";
import ConsentForm from "./submition_GDPR";
import React, {useEffect} from "react";
import {PostStatus, useGDRP} from "../provider_GDPR";
import { useState} from "react";
import {PostSuccessAlert} from "./responses";

export const SubmitContainer = () =>{

    const {dbEndpoint, status, language} = useGDRP()
    // Input Handler
    const [inputs, setInputs] = useState({
        'email':'',
        'name':'',
        'gdpr': false,
        'newsletter':false,
        'language':language,
    });

    useEffect(()=>{
        inputs['language'] = language
    },[language])

    const checkStatus = () => {
        switch (status){
            case PostStatus['Init']:
                return <ConsentForm
                    inputs = {inputs}
                    setInputs = {setInputs}
                />
            case PostStatus['Success']:
                return <PostSuccessAlert/>
            default:
                return <PostResponse inputsData={inputs} uri={dbEndpoint}/>
        }
    }

    return(
        <>
            {checkStatus()}
        </>
    )
}