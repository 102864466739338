
import {useEffect, useRef, useState} from "react";
import {PiInfo} from "react-icons/pi";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';


const InfoIcon = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleHover = () => {
        setIsHovered(!isHovered);
    };
    const iconColor = isHovered ? 'grey' : 'black';
    const [showTip, setTipShow] = useState(false);
    const target = useRef(null);


    return(
        <div style={{ position: 'relative'}}>
            <div onMouseEnter={handleHover} onMouseLeave={handleHover}
                 onTouchStart={handleHover} onTouchEnd={handleHover}
                 ref={target} onClick={() => setTipShow(!showTip)}
                 style={{cursor: 'help', position:"absolute", zIndex: 1 }}
            >
                <PiInfo color={iconColor}
                />
            </div>
            <div style={{cursor: 'help', position:"absolute", zIndex:2 }}>
                <Overlay target={target.current} show={showTip} placement={props.placement}>
                    {(overlay_props) => (
                        <Tooltip id="overlay-example" {...overlay_props}>
                            {props.tip}
                        </Tooltip>
                    )}
                </Overlay>
            </div>
        </div>
    )
}

export default InfoIcon