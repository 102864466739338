import {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {PostStatus, useGDRP} from "../provider_GDPR";

const SubmitBttn = ({ name, children, title, ...props }) => {
    const {status, hasRead} = useGDRP()
    // About OffCanvas
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (status === PostStatus['Success']){
            handleClose()
        }
    }, [status]);

    return (
        <>
            <Button variant="primary"
                    disabled={false}//{!hasRead} Disable or Enable button according to policy // SubmitBUTTON DISAVLE
                    active={false}
                    onClick={handleShow} className="me-2">
                {name}
            </Button>
            <Offcanvas show={show} onHide={handleClose}
                       enforceFocus={true}
                       restoreFocus={false}
                       {...props}
                        style={{height:"auto", maxHeight:"80vh"}}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {children}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default SubmitBttn;