import React, {useState, useEffect} from "react";
import {PostFailureAlert, PostLoadingAlert, PostSuccessAlert} from "./responses";
import {PostStatus, useGDRP} from "../provider_GDPR";


const labels = {
    "email":"email",
    "name":"name",
    "gdpr":"gdpr",
    "newsletter":"newsletter",
    "language":"language"
}

function formDataTemplate(email, name, gdpr, newsletter, language){
    const formData = new FormData()
    if(email){
        //TODO: Do mail evaluation
        formData.append(labels["email"], email)
    }else {
        return null
    }
    if(gdpr){
        formData.append(labels["gdpr"], gdpr)
    }else {
        return null
    }
    formData.append(labels["name"], name)
    formData.append(labels["newsletter"], newsletter)
    formData.append(labels["language"], language)
    return formData
}



function responseEvaluator(response){
    if (response.ok){
        return response.json()
    }
    if (response.status === 422){
        throw new Error('Invalid mail');
    }
}


function usePost(uri, formData){
    const [response, setResponse] = useState()
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true)
    const {statusSuccess, statusLoading, statusError} = useGDRP();

    useEffect(() => {
        statusLoading();
        if (!uri) return;
        fetch(uri, {
                method: "POST",
                body: formData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
            .then(response => responseEvaluator(response))
            .then((post) => {console.log(post)})
            .then(()=> { setLoading(false); setResponse(true); statusSuccess()})
            .catch((err)=>{setError(err); setLoading(false); statusError();  console.log('Error: ', err)})
    }, [uri, formData]);

    // TEST
    // const timer =
    //     new Promise(r => {setTimeout(r, 5000)});
    // useEffect(() => {
    //     timer
    //         .then(()=>{ setLoading(false); statusSuccess(); setError(false); setResponse(true)})
    //         .catch(() => {setLoading(false); statusError(); setError(true); setResponse(false)})
    // }, []);

    return {loading, response, error};
}


export function PostResponse({uri, inputsData, //'http://192.168.2.16:5200/client'
                   renderSuccess = (msg) => (<PostSuccessAlert message={msg}/>),
                   loadingFallback = () => (<PostLoadingAlert/>),
                   renderError = (error) =>  (<PostFailureAlert/>)}){
    const formData =  formDataTemplate(...Object.values(inputsData));
    const jsonDATA = JSON.stringify(inputsData);
    const {loading, response, error} = usePost(uri, jsonDATA)

    if (loading) return loadingFallback();
    if (error) return renderError(error);
    const data = {};
    formData.forEach((value, key) => data[key] = value);
    if (response) return renderSuccess(data);
}