import React from 'react';
import logo from './logo.svg';
import './App.css';
import BLEView from "./BLE/BLEView";
import AppGDPR from "./GDRP/view_GDPR";

function App() {
  return (
        <div className="App">
          {/*<BLEView/>*/}
            <AppGDPR/>
        </div>
  );
}

export default App;
