import React, {useState, useLayoutEffect} from 'react';
import {ProviderGDPR, useGDRP} from "./provider_GDPR";
import RulesView from "./rules_GDPR";
import SubmitBttn from "./submition/submit_bttn";
import {SubmitContainer} from "./submition/submit_container";
import {LanguagesSelect} from "./lang/lang_GDPR";
import skeleton from '../data/skeleton.json'
import {PostSuccessAlert} from "./submition/responses";
const ViewContainer = ({...props}) =>{
    return(
        <div>
            <RulesView/>
        </div>
    )
}

const ViewGDPR = ({...props}) => {
    const {showLangSelect,language, hasAware} = useGDRP();
    // Language
    const [labels, setLabels] = useState({})
    useLayoutEffect(()=>{
        setLabels(skeleton[language]["menu"])
    },[language])

    return(
        <div>
            <div style={{overflow:"hidden"}}>
                <h1>{labels['title']}</h1>
                <LanguagesSelect/>
            </div>
            { showLangSelect ? null:
                <>
                    <ViewContainer/>
                    {hasAware?
                        <PostSuccessAlert/>:
                        <SubmitBttn
                            name={labels['submission']}
                            title={labels['submission_title']}
                            placement='top'>
                            <SubmitContainer/>
                        </SubmitBttn>
                    }
                </>
            }
        </div>
    )
}

const AppGDPR = ({...props}) => {
    // Language

    return(
        <ProviderGDPR>
            <ViewGDPR/>
            {/*<ViewContainer/>*/}
            {/*<SubmitBttn*/}
            {/*    name='Submition' title='GDPR' placement='top'>*/}
            {/*    <SubmitContainer/>*/}
            {/*</SubmitBttn>*/}
            {/*<LanguagesSelect/>*/}
        </ProviderGDPR>
    )
}

export default AppGDPR

