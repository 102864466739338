import Accordion from 'react-bootstrap/Accordion';
import {useEffect, useState} from "react";
import data from '../data/gdpr.json'
import {useGDRP} from "./provider_GDPR";

const RulesView = ({...props}) => {
    const {is_read, language} = useGDRP()
    const sections = data.sections;
    // const notSeen = Array.from({ length: rules.length }, (_, index) => index);

    const [rules, setRules] = useState([])
    const [notSeen, setNotSeen] = useState([])
    useEffect(()=>{
        if (language === "en"){
            setRules(Object.keys(sections.en))
        }else{
            setRules(Object.keys(sections.gr))
        }
    },[language])

    useEffect(() => {
        const ar = Array.from({ length: rules.length }, (_, index) => index)
        setNotSeen(ar)
        // console.log('NotSeenArray: ', {ar})
    }, [rules]);

    const handleSelect = (eventKey) => {
        // console.log('Event Key: ', eventKey)
        const index = notSeen.indexOf(eventKey);
        if (index !== -1) {
            // Remove each rule has read
            notSeen.splice(index, 1);
        }
        if (notSeen.length === 0 ){
            // Enable the submit button
            is_read()
        }
    }

    const SectionItems = rules.map((section, i) => (
        <Accordion.Item eventKey={i} key={i}>
            <Accordion.Header>{section}</Accordion.Header>
            <Accordion.Body>
                {sections[language][section]}
            </Accordion.Body>
        </Accordion.Item>
    ))

    return (
        <Accordion defaultActiveKey="0" onSelect={handleSelect}>
            {
                SectionItems
            }
        </Accordion>
    );
}

export default RulesView;